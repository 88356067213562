
import AvatarImage from "@/components/AvatarImage.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { leaveServer } from "@/services/serverService";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { ServersModule } from "@/store/modules/servers";
export default defineComponent({
  name: "ProfilePopout",
  components: { AvatarImage, CustomButton },
  props: {
    identity: {
      type: String,
      required: true,
    },
    data: {
      type: Object as PropType<{
        id: string;
        serverId: string;
      }>,
      required: true,
    },
  },
  data() {
    return {
      requestSent: false,
      server: ServersModule.servers[this.data.serverId],
    };
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    buttonClicked() {
      if (this.requestSent) return;
      this.requestSent = true;
      leaveServer(this.data.serverId)
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.requestSent = false;
        });
    },
  },
});
